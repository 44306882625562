<template>
  <div class="view pa24" style="position: relative">
    <div class="d-flex">
      <div>
        <el-input clearable class="w221 mr10" v-model="companyName" placeholder="请输入结算企业"/>
        <el-input clearable class="w221 mr10" v-model="paymentNo" placeholder="请输入订单编号"/>
        <el-button type="primary" @click="fnQueryClick">查询</el-button>
      </div>
    </div>
    <commonTable
        :tableData="tableData"
        :loading="loading"
        @handleSizeChange="handleSizeChange"
        @handleCurrentChange="handleCurrentChange"
        @handleSelectionChange="handleSelectionChange"
        :currentPage="currentPage"
        :total="total"
        style="width: 100%;position: relative"
    >
      <template v-slot:table>
        <el-table-column type="selection"/>
        <el-table-column prop="paymentNo" align="center" label="结算单号"/>
        <el-table-column prop="amount" align="center" label="结算金额"/>
        <el-table-column prop="companyName" align="center" label="结算企业"/>
        <el-table-column prop="paymentTime" align="center" label="打款时间"/>
        <el-table-column align="center" label="操作">
          <template slot-scope="scope">
            <el-button type="text" @click="toView(scope.row)">查看凭证</el-button>
            <el-button class="" type="text" @click="toOrderDetails(scope.row)">订单明细</el-button>
            <!--            <el-button class="" type="text" @click="toExport(scope.row)">导出</el-button>-->
          </template>
        </el-table-column>
      </template>
    </commonTable>
    <el-dialog
        title="已结算订单"
        :visible.sync="centerDialogVisible"
        :modal-append-to-body="false"
        width="80%"
        center
    >
      <div style="display: flex">
        <div style="display: flex">
          <div style="align-self: center;margin-right: 10px">订单编号</div>
          <div>
            <el-input
                placeholder="请输入订单编号"
                v-model="order.count"
                clearable
            />
          </div>
        </div>
        <div style="margin-left: 10px">
          <el-button
              class="save"
              type="primary"
              @click="search()"
          >搜索
          </el-button
          >
        </div>
      </div>
      <commonTable
          :tableData="orderList"
          @handleSizeChange="handleSizeChange1"
          @handleCurrentChange="handleCurrentChange1"
          :currentPage="currentPage1"
          :loading="loading1"
          :total="total1"
      >
        <template v-slot:table>
          <!--          <el-table-column type="selection"/>-->
          <el-table-column prop="ordersNo" align="center" label="订单编号" />
          <el-table-column prop="amount" align="center" label="订单金额" />
          <el-table-column prop="createTime" align="center" label="订单创建时间" />
        </template>
      </commonTable>
      <div style="display: flex;padding-bottom: 20px">
        <div style="margin-right: 20px">
          订单总额：￥{{moneyD}}
        </div>
        <div>
          订单总数：{{orderList.length}}单
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import commonTable from "@/components/common/commonTable";
import {
  selectSettlementSheetPageList,selectSettlementDetailsPageList
} from "@/api/settlement";

let base64 = require('js-base64').Base64
export default {
  name: "hasBeenSettledP",
  data() {
    return {
      order:{
        count:''
      },
      orderList:[],
      payment:'',
      memberName: '',
      companyName: '',
      paymentNo: '',
      currentPage: 1, //当前页
      pageSize: 10, //显示条数
      loading: false, //表格加载
      total: 0, //总条数
      currentPage1: 1, //当前页
      pageSize1: 10, //显示条数
      loading1: false, //表格加载
      total1: 0, //总条数
      centerDialogVisible: false,
      tableData: [],
      industryList: [],
      twoCode: '',
      memberId: '',
      multipleSelection: [],
      settlementSheetId:''
    };
  },
  created() {
    this.queryPage()
  },
  components: {
    commonTable
  },
  computed: {
    moneyD: function () {
      let DDmoney = 0;
      for (let i in this.orderList) {
        DDmoney += Number(this.orderList[i].amount)
      }
      return DDmoney.toFixed(2)
    },
  },
  methods: {
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
    /**@method 获取*/
    async queryPage(payload) {
      let data = {
        sheetType:1,
        pageSize: this.pageSize,
        pageNum: this.currentPage,
        associationId: localStorage.getItem("associationId"),
        ...payload
      };
      try {
        this.loading = true;
        const result = await selectSettlementSheetPageList(data);
        this.loading = false;
        const {total, list} = result.data.pageInfo;
        for (let i in list) {
          if (list[i].paymentTime) {
            list[i].paymentTime = this.renderTime(list[i].paymentTime)
          }
          list[i].amount = (list[i].amount/100).toFixed(2)
        }
        this.tableData = list;
        this.total = total;
      } catch (error) {
        this.loading = false;
        this.tableData = [];
      }
    },
    renderTime(date) {
      var dateee = new Date(date).toJSON();
      return new Date(+new Date(dateee) + 8 * 3600 * 1000).toISOString().replace(/T/g, ' ').replace(/\.[\d]{3}Z/, '')
    },
    handleSizeChange(val) {
      this.pageSize = val;
      let data = {
        companyName: this.companyName,
        paymentNo: this.paymentNo,
      };
      this.queryPage(data);
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      let data = {
        companyName: this.companyName,
        paymentNo: this.paymentNo,
      };
      this.queryPage(data);
    },
    /**@method 查询 */
    fnQueryClick() {
      this.currentPage = 1;
      let data = {
        companyName: this.companyName,
        paymentNo: this.paymentNo,
      };
      this.queryPage(data);
    },
    async toOrderDetails(row){
      this.settlementSheetId = row.settlementSheetId
      this.detailsList()
    },
    async detailsList(payload){
      let data = {
        pageSize: this.pageSize1,
        pageNum: this.currentPage1,
        settlementSheetId:this.settlementSheetId,
      };
      if (this.order.count) {
        data.ordersNo = this.order.count
      }
      try {
        this.centerDialogVisible = true
        this.loading1 = true;
        const result = await selectSettlementDetailsPageList(data);
        this.loading1 = false;
        const {total, list} = result.data.pageInfo;
        for (let i in list) {
          if (list[i].createTime) {
            list[i].createTime = this.renderTime(list[i].createTime)
          }
          list[i].amount = (list[i].amount/100).toFixed(2)
        }
        this.orderList = list;
        this.total1 = total;
      } catch (error) {
        this.loading1 = false;
        this.orderList = [];
      }
    },
    search(){
      this.currentPage = 1;
      let data = {
        ordersNo: this.order.count,
      };
      this.detailsList(data);
    },
    handleSizeChange1(val) {
      this.pageSize1 = val;
      let data = {
        ordersNo: this.order.count,
      };
      this.detailsList(data);
    },
    handleCurrentChange1(val) {
      this.currentPage1 = val;
      let data = {
        ordersNo: this.order.count,
      };
      this.detailsList(data);
    },
    toView(row){
      this.$router.push({
        path: "../credentials",
        query: {
          paymentNo: row.paymentNo,
          companyName: row.companyName,
          companyId: row.companyId,
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.code_img {
  position: absolute;
  top: 30%;
  z-index: 10000;
  left: 35%;
  background: #FFFFFF;
}

/deep/ .el-table__body {
  position: relative;
}
</style>



