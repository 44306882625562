import httpUtil from "@/utils/httpUtil";

/** 待结算订单 */
export function selectOrdersPageList(params,type) {
    return httpUtil.post("/api/assPc/settlement/selectOrdersPageList", params, type)
}
/** 新增打款凭证 */
export function addPaymentVoucher(params,type) {
    return httpUtil.post("/api/assPc/settlement/addPaymentVoucher", params, type)
}
/** 查询企业 */
export function selectOrdersCompanyPageList(params,type) {
    return httpUtil.post("/api/assPc/settlement/selectOrdersCompanyPageList", params, type)
}
/** 结算单 */
export function selectSettlementSheetPageList(params,type) {
    return httpUtil.post("/api/assPc/settlement/selectSettlementSheetPageList", params, type)
}
/** 结算单详情 */
export function queryPaymentVoucherByPaymentNo(params,type) {
    return httpUtil.post("/api/assPc/settlement/queryPaymentVoucherByPaymentNo", params, type)
}
/** 已结算订单明细 */
export function selectSettlementDetailsPageList(params,type) {
    return httpUtil.post("/api/assPc/settlement/selectSettlementDetailsPageList", params, type)
}

/** 添加角色 */
export function addOneRole(params,type) {
    return httpUtil.post("/api/assPc/AssRoleMemeber/addOneRole", params, type)
}

/** 角色列表 */
export function queryListPageRole(params,type) {
    return httpUtil.post("/api/assPc/AssRoleMemeber/queryListPageRole", params, type)
}

/** 角色详情 */
export function queryMemnumByRole(params,type) {
    return httpUtil.post("/api/assPc/AssRoleMemeber/queryMemnumByRole", params, type)
}
/** 角色修改 */
export function upateRole(params,type) {
    return httpUtil.post("/api/assPc/AssRoleMemeber/upateRole", params, type)
}
/** 删除修改 */
export function deleteOneRole(params,type) {
    return httpUtil.post("/api/assPc/AssRoleMemeber/deleteOneRole", params, type)
}

/** 创建账号 */
export function addAccAcountInfo(params,type) {
    return httpUtil.post("/api/assPc/AssRoleMemeber/addAccAcountInfo", params, type)
}
/** 账号列表 */
export function queryAcountPage(params,type) {
    return httpUtil.post("/api/assPc/AssRoleMemeber/queryAcountPage", params, type)
}
/** 修改账号 */
export function updateAccAcountInfo(params,type) {
    return httpUtil.post("/api/assPc/AssRoleMemeber/updateAccAcountInfo", params, type)
}
/** 删除账号 */
export function deleteAssAcount(params,type) {
    return httpUtil.post("/api/assPc/AssRoleMemeber/deleteAssAcount", params, type)
}
/** 不分页角色列表 */
export function queryListRole(params,type) {
    return httpUtil.post("/api/assPc/AssRoleMemeber/queryListRole", params, type)
}

/** 菜单列表 */
export function queryListLevelMenum(params,type) {
    return httpUtil.post("/api/assPc/AssRoleMemeber/queryListLevelMenum", params, type)
}








